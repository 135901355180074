<template>
  <!-- <v-dialog v-model="dialog" max-width="1200px" height="500px"> -->
  <v-card class="pa-4" color="white" outlined>
    <v-row>
      <v-card-title>Ingresar nuevo informe</v-card-title>
      <v-spacer></v-spacer>
      <v-btn plain @click.native="closeDialog">
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-row>
    <v-divider class="my-5" />

    <v-row>
      <v-col sm="2">
        <v-radio-group v-model="tipo">
          <v-radio label="Gasto"></v-radio>
          <v-radio label="Obra en curso"></v-radio>
        </v-radio-group>
        <v-radio-group v-model="clase">
          <v-radio label="Fijos"></v-radio>
          <v-radio label="Variable"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col sm="10">
        <v-row>
          <v-col sm="20%">
            <v-text-field
              v-model="id_sap"
              :rules="[(v) => !!v || 'N° SPC is required']"
              maxlength="6"
              prepend-inner-icon="mdi-file-document-outline"
              label="N° SPC"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="20%">
            <v-text-field
              v-model="num_informe"
              :rules="[(v) => !!v || 'N° informe is required']"
              maxlength="6"
              prepend-inner-icon="mdi-file-document-edit-outline"
              label="N° Informe"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="20%">
            <v-menu
              v-model="fpick1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="f_reccomp"
                  label="Fecha recepción"
                  prepend-icon="mdi-calendar-import"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="f_rec"
                @input="fpick1 = false"
                :first-day-of-week="1"
                locale="es-cl"
                max="maxdate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="20%">
            <v-menu
              v-model="fpick2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="f_ingcomp"
                  label="Fecha ingreso"
                  prepend-icon="mdi-calendar-check"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="f_ing"
                @input="fpick2 = false"
                :first-day-of-week="1"
                locale="es-cl"
                max="maxdate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="20%">
            <v-select
              v-model="area"
              :items="area_item"
              prepend-inner-icon="mdi-filter-menu-outline"
              label="Sel. Area"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-mask="['X.XXX.XXX-X', 'XX.XXX.XXX-X']"
              v-model="rut"
              required
              prepend-inner-icon="mdi-card-account-details-outline"
              maxlength="12"
              label="Rut proveedor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="nom_prov"
              label="Nombre proveedor"
              prepend-inner-icon="mdi-account-hard-hat"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="ceco"
              label="CECO"
              prepend-inner-icon="mdi-cash"
              hint="CECOs separados por coma"
              @change="findcecos()"
            ></v-text-field>
          </v-col>
          <v-btn @click.stop="ceco_dib = true" plain>test</v-btn>
          <v-dialog v_model="ceco_dib">
            <v-card>
              <div>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="nom_ceco"
                    :items="nom_ceco"
                    label="Nombre centro de costo"
                    prepend-inner-icon="mdi-image-text"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-text v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-text>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ nom_ceco.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </div>
            </v-card>
          </v-dialog>

          <v-col cols="12" md="1"
            ><span v-if="oc">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="oc_mvx"
                    maxlength="6"
                    label="OC"
                    prepend-inner-icon="mdi-image-text"
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <span>Obra en curso</span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="nom_prov"
              label="Nombre proveedor"
              prepend-inner-icon="mdi-account-hard-hat"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
  <!-- </v-dialog> -->
</template>

<script>
import CostDataService from "../../services/CostDataService";
// import { format, parseISO } from 'date-fns'
export default {
  name: "semaDlg",
  data: () => ({
    // oc:false,
    area_item: [
      { text: "Infraestructura", value: "I" },
      { text: "Mantenimiento", value: "M" },
      { text: "Operaciones", value: "O" },
      { text: "Refrigeracion", value: "R" },
      { text: "Servicios Generales", value: "S" },
    ],
    dialog: false,
    tipo: 0,
    clase: 0,
    ceco: "",
    fpick1: false,
    f_rec: null,
    fpick2: false,
    f_ing: null,
    num_informe: "",
    id_sap: "",
    n_roi: "", // if infra
    area: "",
    rut: "",
    nom_prov: "",
    nom_ceco: [],
    oc_mvx: "", //if @id_sap=="SC-XXX", find OC mvx
    ceco_dib: false,
  }),
  computed: {
    f_reccomp() {
      return this.formatDate(this.f_rec);
    },
    f_ingcomp() {
      return this.formatDate(this.f_ing);
    },
    maxdate() {
      return new Date().toISOString().substr(0, 10);
    },
    oc() {
      if (this.tipo == 1) {
        return true;
      }
      return false;
    },
  },
  watch: {},
  methods: {
    open: function() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    findcecos() {
      let data = {};
      data["val"] = "paico";
      data["ceco"] = this.ceco;
      // console.log(data)
      if (!this.ceco) {
        this.nom_ceco = [];
      } else {
        CostDataService.getCeco(data).then((res) => {
          this.nom_ceco = res.data;
        });
      }
    },
  },

  // mounted() {
  //   this.message = "";
  //   this.getCode(this.$route.params.id);
  //   console.log(this.params.almbusqueda);
  //   this.dialog=true;
  // },
};
</script>

<style>
.edit-form {
  max-width: 1100px;
  margin: auto;
}
</style>
